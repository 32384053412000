<messages>["../Tariff"]</messages>

<template>
  <td>
    <v-row align="center" justify="space-between">
      <v-col md="item.new ? 5 : 7">
        <span class="font-weight-bold">
          {{ tryTranslate (item.name) }}
        </span>
      </v-col>
      <v-col
        v-if="item.new"
        md="2">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon
              color="green"
              medium
              v-on="on">
              new_releases
            </v-icon>
          </template>
          <span v-t="'view.isNew'"/>
        </v-tooltip>
      </v-col>
      <v-col class="my-1" md="availableAndNotShown.length > 0 ? 4 : 5">
        <v-row v-for="ruleKey in availableAndShownRules" :key="ruleKey" class="nameValue my-0 py-0" justify="space-between">
          <v-col class="font-weight-medium shrink my-0 py-0">
            {{ tryTranslateRuleKey (ruleKey) }}:
          </v-col>
          <v-col class="my-0 py-0">
            <rule-value :value="parsedMatchRules[ruleKey]" :is-phase="ruleKey === 'phases'"/>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="availableAndNotShown.length > 0" md="1">
        <match-rules :remaining-keys="availableAndNotShown" :rules="parsedMatchRules"/>
      </v-col>
    </v-row>
  </td>
</template>

<script>
  import MatchRules from './MatchRules'
  import RuleValue from './RuleValue'

  export default {

    components: {
      MatchRules,
      RuleValue
    },

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    computed: {
      parsedMatchRules () {
        return JSON.parse (this.item.matchRules)
      },

      shownRules () {
        return [
          'object-types',
          'name-apices',
          'name-idn',
          'object-packages',
          'redemption-modes',
          'phases',
          'client-types',
          'clients'
        ]
      },

      availableAndShownRules () {
        return Object.keys (this.parsedMatchRules).filter (value => this.shownRules.indexOf (value) !== -1)
      },

      availableAndNotShown () {
        return Object.keys (this.parsedMatchRules).filter (value => this.shownRules.indexOf (value) === -1)
      }
    },

    methods: {
      tryTranslate (name) {
        const names = name.split (', ')

        const translatedNames = names.map ((n) => {
          const label = n.replace ('.', '-')
          return this.translationHelper (`tariffnames.${label}`)
        })

        return translatedNames.reduce (
          (acc, t) => acc === '' ? t : `${acc}, ${t}`,
          ''
        )
      },

      tryTranslateRuleValue (value) {
        return this.translationHelper (`matchRules.value.${value}`)
      },

      tryTranslateRuleKey (value) {
        return this.translationHelper (`matchRules.key.${value}`)
      },

      translationHelper (value) {
        return this.$t (value) === value
          ? value
          : this.$t (value)
      }
    }
  }
</script>

<style lang="scss">
  @import '~vuetify/src/styles/settings/_colors';

  $oddColor: map-get($grey, "lighten-2");
  $evenColor: map-get($grey, "lighten-5");

  .nameValue {
    background-color: $oddColor;
  }

  .nameValue:nth-child(even) {
    background-color: $evenColor;
  }
</style>
