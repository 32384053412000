<messages>["../Tariff"]</messages>

<template>
  <v-tooltip
    v-if="remainingKeys.length > 0"
    bottom>
    <template #activator="{on}">
      <v-icon
        dark
        color="primary"
        v-on="on">
        call_split
      </v-icon>
    </template>
    <span>
      <template v-for="key in remainingKeys">
        {{ translateKey (key) }}:
        {{ getRepresentation (rules[key], key) }}
        <br :key="key">
      </template>
    </span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'MatchRules',

    props: {
      rules: {
        type: Object, required: true
      },
      remainingKeys: {
        type: Array,
        default: () => ([])
      }
    },

    methods: {
      getRepresentation (prop, key) {
        if (prop === '!') {
          return this.$t ('matchRules.value.matchNone')
        }
        if (prop === '*') {
          return this.$t ('matchRules.value.matchAnyOrNone')
        }
        if (prop === '?') {
          return this.$t ('matchRules.value.matchAny')
        }
        if (Array.isArray (prop) && key !== 'phases') {
          return prop.reduce ((acc, item) => {
            return acc === ''
              ? this.tryTranslate (item, key === 'actions')
              : `${acc}, ${this.tryTranslate (item, key === 'actions')}`
          }, '')
        }
      },

      tryTranslate (value, actions) {
        let path = ''
        if (actions) {
          path = `tariffnames.${value.replace ('.', '-')}`
        } else {
          path = `matchRules.key.${value}`
        }

        return this.$t (path) === path
          ? value
          : this.$t (path)
      },

      translateKey (value) {
        return this.$t (`matchRules.key.${value}`) === `matchRules.key.${value}`
          ? value
          : this.$t (`matchRules.key.${value}`)
      }
    }
  }
</script>
