<messages>["./Tariff"]</messages>

<template>
  <base-layout mw3>
    <v-col cols="12">
      <v-card class="transition">
        <v-card-title primary-title>
          <v-row>
            <v-col cols="12" sm="8">
              <div>
                <div
                  v-t="'view.title'"
                  class="text-h5"/>
                <div
                  v-t="'view.subtitle'"
                  class="cgwng-subheading"/>
              </div>
            </v-col>
            <v-col v-if="isAdmin">
              <v-switch v-model="showRegistrarCharges" :label="$t ('view.showRegistrarCharges')"/>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row
            class="mb-4"
            justify="space-between">
            <v-col cols="12">
              <radio-group
                :value="type"
                :option-values="[types.REGISTRY, types.ZONE]"
                :option-labels="[$t ('view.registries'), $t ('view.zone')]"
                @input="typeChanged"/>
            </v-col>
            <v-slide-y-transition mode="out-in">
              <v-col cols="12">
                <v-row v-if="type === types.REGISTRY">
                  <v-col cols="12" sm="6">
                    <registry-select
                      v-model="selected"
                      spellcheck="false"
                      :label="$t ('view.registry')"/>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      ref="tldSelect"
                      v-model="selectedTld"
                      clearable
                      :label="$t ('view.tld')"
                      :loading="isLoadingRegistry"
                      :disabled="isTldDisabled"
                      :items="tlds"/>
                  </v-col>
                  <v-col v-if="isAdmin" cols="12">
                    <registrar-select
                      v-model="registrarId"
                      all-selectable
                      :label="$t('view.registrar')"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-slide-y-transition>
          </v-row>
          <v-slide-y-transition mode="out-in">
            <v-container
              v-if="isLoading"
              key="loading">
              <v-row>
                <v-progress-linear
                  indeterminate
                  color="primary"/>
              </v-row>
            </v-container>
            <v-container
              v-if="timeline && !isLoading && !noData && !error"
              key="content"
              class="pa-0">
              <history-panel
                v-for="(data, idx) in timeline"
                :key="idx"
                :expanded="isExpanded (data.from, data.to)"
                :seq-number="idx">
                <template #label>
                  <span v-text="idx + 1"/>
                </template>
                <template #start>
                  <span v-text="formatDateShort (data.from)"/>
                </template>
                <template #end>
                  <span v-text="data.to ? formatDateShort (data.to) : $t ('general.history.indefinitely')"/>
                </template>
                <template #content>
                  <tariff-frame :frame="data" :charge-for="chargeFor"/>
                </template>
              </history-panel>
            </v-container>
            <v-container
              v-if="noData && !isLoading"
              key="noData">
              <v-alert type="error">
                {{ type === types.REGISTRY
                  ? $t ('view.nodata') : $t
                    ('view.nozonedata') }}
              </v-alert>
            </v-container>
            <v-container
              v-else-if="error && !isLoading"
              key="error">
              <v-alert
                v-t="'view.error'"
                type="error"/>
            </v-container>
          </v-slide-y-transition>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import RegistrySelect from '@/app/core/components/RegistrySelect'
  import HistoryPanel from '@/app/core/components/HistoryPanel'

  import RadioGroup from '@/app/core/components/RadioGroup'

  import TariffFrame, {charges} from './components/TariffTimeframe'
  import RegistrarSelect from './components/RegistrarSelect'

  const REGISTRY = 'reg'
  const ZONE = 'zone'

  export default {
    name: 'TariffView',

    components: {
      BaseLayout,
      HistoryPanel,
      RegistrySelect,
      TariffFrame,
      RadioGroup,
      RegistrarSelect
    },

    data () {
      return {
        isLoadingReg: false,
        isLoading: false,
        registries: [],
        search: '',
        totalCount: 0,
        type: REGISTRY,
        selected: null,
        timeline: null,
        noData: false,
        error: false,
        registryMetadata: null,
        selectedTld: null,
        isLoadingRegistry: false,
        showRegistrarCharges: false,
        registrarId: 0
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'hasAllOfPermissions'
      ]),

      types () {
        return {
          REGISTRY,
          ZONE
        }
      },

      chargeFor () {
        return this.showRegistrarCharges ? charges.REGISTRAR : charges.CLIENT
      },

      isAdmin () {
        return this.hasAllOfPermissions (['ManageAllObjects'])
      },

      tlds () {
        return this.registryMetadata
          ? [
            {text: this.$t ('general.label.any'), value: '*'},
            ...this.registryMetadata.baseNames.map ((tld) => `.${tld}`)
          ]
          : []
      },

      isTldDisabled () {
        return this.isLoadingRegistry ||
          (this.registryMetadata && this.registryMetadata.baseNames?.length === 1)
      }
    },

    watch: {
      selected () {
        if (this.selected) {
          this.selectedTld = null
          this.timeline = null
          this.noData = false
          this.error = false
          this.loadTariff (this.selected)
          this.loadTlds (this.selected)
        }
      },

      selectedTld () {
        this.loadTariff (this.selected)
      },

      registrarId () {
        this.loadTariff (this.selected)
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadTlds (id) {
        this.isLoadingRegistry = true

        this.fetchData ({
          op: 'registry/loadMetaData',
          params: {
            registryId: id
          },
          cb: data => {
            this.registryMetadata = data.registryMetaData

            if (this.registryMetadata.baseNames?.length === 1) {
              this.selectedTld = `.${this.registryMetadata.baseNames[0]}`
            } else {
              this.$refs.tldSelect.lazySearch = ''
            }
          },
          cbFinal: () => {
            this.isLoadingRegistry = false
          }
        })
      },

      /**
       * Toggle between zone and registry tariffs
       *
       * @param type    value of the selected type
       */

      typeChanged (type) {
        this.type = type

        if (type === ZONE) {
          this.loadTariff ()
        } else {
          this.timeline = []
          this.selected = null
          this.noData = false
        }
      },

      /**
       * Loads the tariff from the backend for a specific registry id
       *
       * @param regId registry id
       */

      loadTariff (regId) {
        if ((!(regId && this.selectedTld) && this.type !== ZONE) || this.isLoading) { return }

        this.isLoading = true
        this.noData = false
        this.error = false

        const request = {
          op: 'tariff/load',
          params: {
            registry: regId,
            tld: this.selectedTld === '*' ? null : this.selectedTld,
            registrarId: this.isAdmin ? this.registrarId : 0
          },
          cb: data => {
            this.timeline = data.timeline
            if (this.timeline.length === 0) {
              this.noData = true
            }
          },
          cbCatch: () => {
            this.error = true
          },
          cbFinal: () => {
            this.isLoading = false
          }
        }

        this.fetchData (request)
      },

      /**
       *
       *
       */
      isExpanded (from, to) {
        return from < Date.now () && (to ? to > Date.now () : true)
      }
    }
  }
</script>

<style scoped>
  .transition {
    overflow: hidden;
    -webkit-transition: height 0.3s ease-out; /* Safari */
    transition: height 0.3s ease-out;
  }
</style>
