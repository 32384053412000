<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <v-expansion-panels
    v-model="value"
    multiple

    class="elevation-0">
    <v-expansion-panel class="noShadow" @input="emitState">
      <v-expansion-panel-header :class="seqClass" style="height:60px">
        <v-row align="center">
          <v-col
            cols="1"
            style="min-width:60px">
            <v-chip
              outlined
              small>
              <slot name="label"/>
            </v-chip>
          </v-col>
          <v-col class="markable-text">
            <slot name="start"/>
            <span v-if="showDash">
              &#x2014;
            </span>
            <slot name="end"/>
          </v-col>
          <v-col>
            <slot name="operation"/>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="content"/>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  export default {
    name: 'HistoryPanel',

    props: {
      expanded: Boolean,
      showDash: {type: Boolean, default: true},
      seqNumber: {type: Number, default: 0}
    },

    computed: {
      value: {
        get () {
          return this.expanded ? [0] : []
        },
        set (newValue) {
          this.emitState (newValue[0])
        }
      },

      seqClass () {
        return this.seqNumber % 2 === 0 ? 'even' : 'odd'
      }
    },

    watch: {
      expanded (newValue) {
        this.emitState (newValue)
      }
    },

    created () {
      this.emitState (this.expanded)
    },

    methods: {
      emitState (expanded) {
        this.$emit (expanded || expanded === 0 ? 'expand' : 'collapse')
      }
    }
  }
</script>

<!--
================================================================================
  Styling (CSS)
================================================================================
-->

<style lang="scss">
@import '~vuetify/src/styles/settings/_colors';

$oddColor: map-get($grey, "lighten-5");
$evenColor: map-get($grey, "lighten-3");

.noShadow::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.even {
  background-color: $evenColor;
}

.odd {
  background-color: $oddColor;
}

.markable-text {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
</style>
