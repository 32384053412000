<messages>["../Tariff"]</messages>
<template>
  <v-row>
    <v-col
      v-if="timeDependent.length > 0 || timeDependentMonth.length > 0"
      class="pa-4"
      cols="12">
      <div>
        <div
          v-t="'view.titleyears'"
          class="text-h6 mb-4"/>
      </div>
      <v-data-table
        v-if="timeDependent.length > 0"
        :headers="headersYears"
        :items="timeDependent"
        hide-default-footer
        :items-per-page="-1"
        class="elevation-1">
        <template #item="props">
          <tr>
            <tariff-name-column :item="props.item"/>
            <td
              v-for="i in tableSize"
              :key="props.item.type + i"
              class="text-right">
              <template v-if="props.item.charge.values[i-1]">
                {{
                  currency(props.item.charge.values[i-1], 'value',
                           props.item.charge.currency)
                }}
                <br>
                <template v-if="props.item.charge.values[i-1].change>0">
                  <span class="red--text text-caption">
                    <v-icon
                      color="red"
                      small>
                      arrow_upward
                    </v-icon>
                    {{
                      currency(props.item.charge.values[i-1], 'change', props.item.charge.currency)
                    }}
                  </span>
                </template>
                <template v-else-if="props.item.charge.values[i-1].change<0">
                  <span class="green--text text-caption">
                    <v-icon
                      color="green"
                      small>
                      arrow_downward
                    </v-icon>
                    {{
                      currency(props.item.charge.values[i-1], 'change', props.item.charge.currency)
                    }}
                  </span>
                </template>
              </template>
              <template v-else>
                -
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-data-table
        v-if="timeDependentMonth.length > 0"
        :headers="headersMonth"
        :items="timeDependentMonth"
        hide-default-footer
        :items-per-page="-1"
        class="elevation-1 mt-3">
        <template #item="props">
          <tr>
            <tariff-name-column :item="props.item"/>
            <td
              v-for="i in tableSize"
              :key="props.item.type + i"
              class="text-right">
              <template v-if="props.item.charge.values[i-1]">
                {{
                  currency(props.item.charge.values[i-1], 'value',
                           props.item.charge.currency)
                }}
                <br>
                <template v-if="props.item.charge.values[i-1].change>0">
                  <span class="red--text text-caption">
                    <v-icon
                      color="red"
                      small>
                      arrow_upward
                    </v-icon>
                    {{
                      currency(props.item.charge.values[i-1], 'change', props.item.charge.currency)
                    }}
                  </span>
                </template>
                <template v-else-if="props.item.charge.values[i-1].change<0">
                  <span class="green--text text-caption">
                    <v-icon
                      color="green"
                      small>
                      arrow_downward
                    </v-icon>
                    {{
                      currency(props.item.charge.values[i-1], 'change', props.item.charge.currency)
                    }}
                  </span>
                </template>
              </template>
              <template v-else>
                -
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col
      v-if="fixed.length > 0"
      class="pa-4"
      cols="12">
      <div>
        <div
          v-t="'view.titleonetime'"
          class="text-h6 mb-4"/>
      </div>
      <v-data-table
        :headers="headersOnetime"
        :items="fixed"
        hide-default-footer
        :items-per-page="-1"
        class="elevation-1">
        <template #item="props">
          <tr>
            <tariff-name-column :item="props.item"/>
            <td v-if="!props.item.charge.noCharge" class="text-right">
              {{ props.item.charge.fixedPrice
                ? formatMoneyAmount (props.item.charge.fixedPrice,
                                     props.item.charge.currency, $i18n.locale)
                : props.item.charge.value
                  ? props.item.charge.value
                  : `${formatFormula (props.item.charge.formula)} ${$t
                    ('view.in')} ${props.item.charge.currency}` }}
            </td>
            <td v-else class="text-right">
              {{ $t ('view.noRegistrarCharge') }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col
      v-if="external.length > 0"
      class="pa-4"
      cols="12">
      <div>
        <div
          v-t="'view.titleexternal'"
          class="text-h6 mb-4"/>
      </div>
      <v-data-table
        :headers="headersOnetime"
        :items="external"
        :items-per-page="-1"
        hide-default-footer
        class="elevation-1">
        <template #item="props">
          <tr>
            <tariff-name-column :item="props.item"/>
            <td class="text-right">
              {{ props.item.charges[chargeFor].fixedPrice
                ? formatMoneyAmount (props.item.charge.fixedPrice,
                                     props.item.charge.currency, $i18n.locale)
                : props.item.charge.value
                  ? props.item.charge.value
                  : `${formatFormula (props.item.charge.formula)} ${$t
                    ('view.in')} ${props.item.charge.currency}` }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
  import TariffNameColumn from './TariffNameColumn'

  const YEAR_PARAM = 'Years'
  const MONTH_PARAM = 'Months'

  export const charges = {
    CLIENT: 'client',
    REGISTRAR: 'registrar'
  }

  export default {
    components: {
      TariffNameColumn
    },

    props: {
      frame: {
        type: Object,
        required: true
      },
      chargeFor: {
        type: String,
        default: charges.CLIENT
      }
    },

    computed: {
      timeDependent () {
        return this.transformOperations ((charge) => {
          return !charge.noCharge && charge.args?.length === 1 &&
            charge.args[0] === YEAR_PARAM
        })
      },

      timeDependentMonth () {
        return this.transformOperations ((charge) => {
          return !charge.noCharge && charge.args?.length === 1 &&
            charge.args[0] === MONTH_PARAM
        })
      },

      fixed () {
        return this.transformOperations ((charge) => {
          return charge.noCharge || !charge.args ||
            charge.args.length === 0
        })
      },

      external () {
        return this.transformOperations ((charge) => {
          return !charge.noCharge && charge.args &&
            charge.args.length > 0 && (charge.args.length === 1
              ? charge.args[0] !== YEAR_PARAM && charge.args[0] !== MONTH_PARAM
              : true)
        })
      },

      tableSize () {
        return this.$vuetify.breakpoint.lgAndDown
          ? this.$vuetify.breakpoint.mdAndDown
            ? this.$vuetify.breakpoint.smAndDown
              ? [1]
              : [1, 2, 3]
            : [1, 2, 3, 4, 5]
          : [1, 2, 3, 4, 5, 6, 7, 8]
      },

      headersYears () {
        return [
          {
            text: this.$t ('view.table.operation'),
            value: 'type'
          },
          ...this.tableSize.map (i => {
            return {
              text: i + ' ' + this.$tc ('view.table.year', i),
              value: 'value',
              sortable: false,
              align: 'right'
            }
          })]
      },

      headersMonth () {
        return [
          {
            text: this.$t ('view.table.operation'),
            value: 'type'
          },
          ...this.tableSize.map (i => {
            return {
              text: i + ' ' + this.$tc ('view.table.month', i),
              value: 'value',
              sortable: false,
              align: 'right'
            }
          })]
      },

      headersOnetime () {
        return [
          {
            text: this.$t ('view.table.operation'),
            value: 'type'
          },
          {
            text: this.$t ('view.table.priceperoperation'),
            value: 'price',
            sortable: false,
            align: 'right'
          }
        ]
      }
    },

    methods: {
      transformOperations (filter) {
        const ops = []

        this.frame.operations.forEach (op => {
          const charge = {
            ...(op.charges[this.chargeFor]
              ? {
                ...op.charges[this.chargeFor],
                payer: this.chargeFor,
                noCharge: false
              }
              : {noCharge: true})
          }

          if (filter (charge)) {
            ops.push ({
              ...op,
              ...(!op.noCharge
                ? {
                  type: `${op.type} ${this.$t ('label.for')} ${this.$t ('label.' + charge.payer)}`,
                  payer: charge.payer,
                  charge,
                  formular: charge.formula
                }
                : {charge})
            })
          }
        })

        ops.sort ((a, b) => {
          const nameA = a.type
          const nameB = b.type

          if (!nameA && nameB) {
            return -1
          }

          if (nameA && !nameB) {
            return 1
          }

          if (!nameA && !nameB) {
            return 0
          }

          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          return 0
        })

        return ops
      },

      formatFormula (formula) {
        return `p = ${formula}`
      },

      /**
       * Translates a currency object into the right language selected by user
       *
       * @param obj         object containing the amount value and currency
       * @param prop        object property to be used to get the amount value
       * @param currency    currency name
       */

      currency (obj, prop = 'value', currency = 'USD') {
        return this.formatMoneyAmount (
          obj[prop],
          currency,
          this.$i18n.locale)
      }
    }
  }
</script>
