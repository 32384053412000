<messages>["../Tariff"]</messages>

<template>
  <div style="display: inline;">
    <template v-if="isSymbol">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span v-on="on">
            {{ value }}
          </span>
        </template>
        {{ getRepresentation (value) }}
      </v-tooltip>
    </template>
    <span v-else-if="isPhase">
      {{ reducePhases (value) }}
    </span>
    <span v-else>
      {{ getRepresentation (value) }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: [String, Boolean, Array],
        default: '-'
      },
      isPhase: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      isSymbol () {
        return ['*', '?', '!'].indexOf (this.value) !== -1
      }
    },

    methods: {
      getRepresentation (prop, key) {
        if (prop === '!') {
          return this.$t ('matchRules.value.matchNone')
        }
        if (prop === '*') {
          return this.$t ('matchRules.value.matchAnyOrNone')
        }
        if (prop === '?') {
          return this.$t ('matchRules.value.matchAny')
        }
        if (Array.isArray (prop) && key !== 'phases') {
          return prop.reduce ((acc, item) => {
            return acc === ''
              ? this.tryTranslate (item)
              : `${acc}, ${this.tryTranslate (item)}`
          }, '')
        }
      },

      reducePhases (phases) {
        return phases.reduce ((acc, item) => {
          let toAdd
          if (Object.keys (item).length === 0) {
            toAdd = `<${this.$t ('label.undefined')}>`
          } else {
            toAdd = `${this.tryTranslate (item.phase)}${item.subphase ? '(' + item.phase + ')' : ''}`
          }

          return acc === ''
            ? toAdd
            : `${acc}, ${toAdd}`
        }, '')
      },

      tryTranslate (value) {
        return this.$t (`matchRules.value.${value}`) === `matchRules.value.${value}`
          ? value
          : this.$t (`matchRules.value.${value}`)
      }
    }
  }
</script>
