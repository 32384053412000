<template>
  <v-autocomplete
    v-model="registrarId"
    :class="{required}"
    :loading="isLoading"
    :clearable="clearable"
    :label="label"
    :items="registrars || []"
    :disabled="disabled"
    item-value="id"
    :error-messages="errorMessages"
    @blur="$emit('blur', $event)">
    <template #append-item>
      <v-list-item @click.prevent="reloadRegistrars">
        {{ $t('general.button.reload') }}
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
  import {mapActions} from 'vuex'

  export default {
    props: {
      value: {
        type: Number,
        default: undefined
      },
      disabled: {
        type: Boolean,
        default: false
      },
      errorMessages: {
        type: Array,
        default: () => ([])
      },
      label: {
        type: String,
        default: undefined
      },
      allSelectable: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        registrars: null,
        isLoading: false
      }
    },

    computed: {
      registrarId: {
        get () {
          return this.value
        },
        set (newVal) {
          this.$emit ('input', newVal)
          const selected = this.registrars?.filter ((r) => r.id === newVal)
          this.$emit ('input:displayName', selected?.length ? selected[0]?.text : undefined)
        }
      }
    },

    watch: {
      value (newVal) {
        const selected = this.registrars?.filter ((r) => r.id === newVal)
        this.$emit ('input:displayName', selected?.length ? selected[0]?.text : undefined)
      }
    },

    created () {
      this.listRegistrars ()
    },

    methods: {
      ...mapActions ({
        getRegistrars: 'cache/getRegistrars',
        clearRegistrars: 'cache/clearRegistrars'
      }),

      reloadRegistrars () {
        this.clearRegistrars ()
        this.listRegistrars ()
      },

      // retrieve the list of registrars
      async listRegistrars () {
        this.isLoading = true
        const regObjects = await this.getRegistrars ()
        // add 'text' property to be used as label
        this.registrars = [
          ...this.allSelectable
            ? [{
              text: this.$t ('general.label.any'),
              id: 0
            }]
            : [],
          ...regObjects.map (
            elem => ({
              ...elem,
              text: elem.displayName + (elem.ianaId ? ` (${elem.ianaId})` : '')
            })
          )]

        if (this.value) { this.$emit ('input:displayName', this.registrars.filter ((r) => r.id === this.value)?.[0].text) }
        this.isLoading = false
      }
    }
  }
</script>
